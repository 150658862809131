

  export default {
    name: `MyCheckInternetProfileStatus`,
    methods: {
      show: () => {return [`ordered_placed`, `in_progress`].includes(this.myCheck?.status.code)}
    },
    computed: {
      fullCandidateName() {
        return `${this.myCheck?.candidate?.first_name} ${this.myCheck?.candidate?.last_name}`
      }
    },
    props: {
      myCheck: {
        type: Object,
        default: undefined
      }
    },
  }

